import React, { Component } from "react";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import Pagination from "../../components/Pagination";
import ReactHTMLParser from "react-html-parser";
import NavBar from "../../components/NavBar";
import AuthorsGrid from "../../components/Blog/AuthorsGrid";
import ContactBanner from "../../components/ContactBanner";
import Hero from "../../components/Hero";

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 1500px;
  .nav-bar {
    padding-bottom: 0;
  }
  .related-blogs-heading {
    font-size: 40px;
    text-align: center;
    padding-top: 2em;
    margin-bottom: 1em;
  }
  .author-bio {
    white-space: pre-line;
  }
`;

const StyledAuthorInfoContainer = styled.div`
  width: 100%;
  
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    padding: 60px 0;
  }
`;
const StyledAuthorCopy = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    width: 60%;
  }
`;
const StyledAuthorImage = styled.div`
  width: 100%;
  img {
    border-radius: 50%;
  }
  @media (min-width: ${breakpoints.tablet}) {
    width: 17%;
    margin-right: 20px;
  }
`;

class AuthorBlogPosts extends Component {
  render() {
    const props = this.props;
    const { group: posts } = this.props.pathContext;
    const { pathContext } = this.props;

    const description = pathContext.additionalContext.short_bio;
    const allTopics = props.data.allWordpressWpTopic.edges;
    const shortBio = description.replace(/(<([^>]+)>)/gi, ""); //stripping html
    const author =
      "Ansira Blog Contributor " + pathContext.additionalContext.author;
    
    return (
      <StyledContainer className="animate">
        <Helmet>
          {/* General tags */}
          <title>{author}</title>
          <meta name="description" content={shortBio} />
          <meta name="image" content={pathContext.additionalContext.photo} />

          {/* OpenGraph tags */}
          <meta property="og:url" content="/blog" />
          <meta property="og:type" content="website" />

          <meta property="og:title" content={author} />
          <meta property="og:description" content={shortBio} />
          <meta
            property="og:image"
            content={pathContext.additionalContext.photo}
          />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="https://twitter.com/ansira" />
          <meta name="twitter:title" content={author} />
          <meta name="twitter:description" content={shortBio} />
          <meta
            name="twitter:image"
            content={pathContext.additionalContext.photo}
          />
        </Helmet>
        <NavBar link="/insights/blog" name="Blog"  className="page-container"/>
        <h1 className="page-container">{pathContext.additionalContext.author}</h1>
        <StyledAuthorInfoContainer className="page-container">
          <StyledAuthorImage>
            <img
              className="headshot"
              src={pathContext.additionalContext.photo}
              alt={"Photo of " + pathContext.additionalContext.author}
            />
          </StyledAuthorImage>

          <StyledAuthorCopy>
            <h4>{pathContext.additionalContext.author_title}</h4>
            <p className="author-bio">
              {ReactHTMLParser(pathContext.additionalContext.long_bio)}
            </p>
          </StyledAuthorCopy>
        </StyledAuthorInfoContainer>
        <div className="clearfix" />
        <div className="post-area page-container">
          <h2 className="related-blogs-heading">
            Articles{" "}
            {props.pathContext
              ? "by " + props.pathContext.additionalContext.author
              : null}
          </h2>

          <AuthorsGrid
            posts={props.pathContext.group ? props.pathContext.group : [] }
          />
        </div>
        <Pagination
          pathContext={pathContext}
          pagePath={"blog/author/" + pathContext.additionalContext.slug}
        />
        <ContactBanner />
      </StyledContainer>
    );
  }
}

export default AuthorBlogPosts;


export const query = graphql`
  query {
    allWordpressWpTopic {
      edges {
        node {
          id
          name
          slug
          description
          wordpress_id
        }
      }
    }
  }
`;