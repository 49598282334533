import React, { Component } from 'react';
import { Link, StaticQuery } from 'gatsby';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import Tag from '../Tag';
import ButtonGrey from '../ButtonGrey';

const StyledPost = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid ${colors.secondaryGray};
  .image-box {
    width: 100%;
    overflow: hidden;
    img {
      margin-bottom: 0;
    }
  }

  .item-content {
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1em;
    background-color: ${colors.white};
  }
  .post-excerpt {
    flex: 1 1 auto;
    p {
      margin-bottom: 1em;
    }
  }
  .post-category {
    letter-spacing: 2px;
    margin-bottom: 0;
    padding: 0;
    text-transform: uppercase;
    color: ${colors.blue};
    font-weight: bold;
    font-size: 14px;
  }
  .post-author {
    display: inline;
    margin-bottom: 0.75em;
  }
`;

const BlogAuthorPostItem = ({ item, className }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpTopic {
            edges {
              node {
                id
                name
                description
                wordpress_id
              }
            }
          }
        }
      `}
      render={data => {
        const allTopics = data.allWordpressWpTopic.edges;
        return (
          <StyledPost className={className}>
            <Link to={'/insights/blog/' + item.slug}>
              <div className="image-box">
                <img
                  src={item.better_featured_image.source_url}
                  alt={item.title}
                />
              </div>
            </Link>
            <div className="item-content">
              <p className="post-category">
                {ReactHtmlParser(item.acf.primary_topic)}
              </p>
              <p className="post-author">
                By{' '}
                <Link to={`/blog/author/` + item.author.slug}>
                  {item.author.name}
                </Link>
                {' | '}
                {item.date}
              </p>
              <Link to={'/insights/blog/' + item.slug} className="post-title">
                {item.title}
              </Link>
              <div className="post-excerpt">
                {ReactHtmlParser(item.acf.excerpt)}
              </div>
              <div className="post-link">
                <ButtonGrey
                  textLabel="Read More"
                  pageLink={'/insights/blog/' + item.slug}
                />
              </div>
            </div>
          </StyledPost>
        );
      }}
    />
  );
};
export default BlogAuthorPostItem;
