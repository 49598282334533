import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors, breakpoints } from '../../style-utilities/variables';
import AuthorPostItem from './AuthorPostItem';

const Div = styled.div`
  margin: 0 auto;
  display: -ms-grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1fr;
  grid-gap: 2em;
  max-width: 35em;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 1500px;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr;
    -ms-grid-columns: 1fr 2em 1fr;

    .grid-item:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }
    .grid-item:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .grid-item:nth-child(3) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
    .grid-item:nth-child(4) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }
    .grid-item:nth-child(5) {
      -ms-grid-row: 1;
      -ms-grid-row: 5;
    }
    .grid-item:nth-child(6) {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }
  }
  }
`;

const AuthorsGrid = ({ posts, props }) => {
  
  return (
    <Div className="insights-grid">
      {posts.map(({ node }, index) => {
        return <AuthorPostItem key={node.slug} item={node} index={index}  className="grid-item" />;
      })}
    </Div>
  );
};

export default AuthorsGrid;

