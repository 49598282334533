import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../style-utilities/variables";
import Arrow from "../components/Arrow";
import ArrowLeft from "../components/ArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight
} from "@fortawesome/free-regular-svg-icons";

const isSamePath = function(path1, path2) {
  var pathSame = path1 === path2 ? true : false;
  if (!pathSame) {
    pathSame = path1 === path2 + "/" ? true : false;
  }
  return pathSame;
};

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 1em;
  margin: 0 auto;
  width: 100%;
  a {
    color: ${colors.primaryGray};
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    text-decoration: none;
  }
  a.active {
    font-weight: bold;
    text-decoration: none;
  }
  a:focus {
    outline: none;
  }
  li {
    position: relative;
    min-width: 18px;
  }
  .page-link:hover {
    background-color: white;
  }

  .pagination {
    margin: 0;
    li a {
      width: 2em;
      height: 2em;
      @media (min-width: ${breakpoints.tablet}) {
        width: 2.5em;
        height: 2.5em;
      }
    }
  }
  .pagination-arrow {
    border: none;
    padding: 0;
  }
  .prev {
    margin-right: 45px;
  }
  .next {
    margin-left: 45px;
  }
  .page-item.active .page-link {
    border: none;
  }
  .page-link:focus {
    box-shadow: none;
  }
  .hide {
    display: none;
  }
  .pagination-ellipsis{
    color: ${colors.primaryGray};
    position: absolute;
    top: 4px;
  }
`;

const Pagination = props => {
  let { first, last, pageCount } = props.pathContext;
  let pagePath = props.pagePath;
  const pgIndex = props.pathContext.index;

  const previousUrl = pgIndex - 1 == 1 ? "" : (pgIndex - 1).toString();
  const nextUrl = (pgIndex + 1).toString();
  const paginationLinks = [];

  // A sweet helper function to create pagination object
  const createPaginationObjects = (length, page, increment = 2) =>
    Array.from({ length }, (_, i) => ({
      link: `/${pagePath}/${i + increment}/`,
      index: i + increment,
      current: page === i + increment
    }));

  // generate a link for each page
  for (let i = 0; i < pageCount; i++) {
    var pagiPath = i != 0 ? pagePath + "/" + (i + 1) : pagePath;
    var pagiClass = pgIndex - 1 == i ? "page-item active" : "page-item";

    paginationLinks.push(
      <li className={pagiClass} key={i.toString()}>
        <Link key={i} to={pagiPath} className={pagiClass}>
          {i + 1}
        </Link>
      </li>
    );
  }

  // Create the navigation link
  let navItems = [
    {
      link: pagePath,
      index: 1,
      current: pgIndex === 1
    }
  ];
  if (pageCount <= 5) {
    navItems = [
      ...navItems,
      ...Array.from({ length: pageCount - 1 }, (_, i) => ({
        link: `/${pagePath}/${i + 2}`,
        index: i + 2,
        current: pgIndex === i + 2
      }))
    ];
  } else {
    // We have a situation where we have to show the first
    // item, three items around the current one
    // and also the last item
    /* eslint-disable no-lonely-if */
    if (pgIndex <= 3) {
      // If the current one is closer to the start
      navItems = [
        ...navItems,
        ...createPaginationObjects(3, pgIndex),
        {
          separator: true,
          index: "starter-separator"
        },
        {
          link: `/${props.pagePath}/${pageCount}/`,
          index: pageCount,
          current: false
        }
      ];
    } else if (pgIndex > pageCount - 3) {
      // If the current one is closer to the last one
      navItems = [
        ...navItems,
        {
          separator: true,
          index: "finisher-separator"
        },
        ...createPaginationObjects(4, pgIndex, pageCount - 3)
      ];
    } else {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: "starter-separator"
        },
        ...createPaginationObjects(3, pgIndex, pgIndex - 1),
        {
          separator: true,
          index: "finisher-separator"
        },
        {
          link: `/${props.pagePath}/${pageCount}/`,
          index: pageCount,
          current: false
        }
      ];
    }
  }

  //set class for back/forward buttons based on which page we are on
  var backClass = first ? "page-item disabled" : "page-item";
  var forwardClass = last ? "page-item disabled" : "page-item";

  if (pageCount > 1) {
    return (
      <StyledPagination>
        <ul className="pagination">
          {/* output previous page navigation */}
          <li className={backClass}>
            <Link
              to={`${props.pagePath}/${previousUrl}`}
              className="page-link pagination-arrow"
              rel="nofollow"
            >
              <ArrowLeft/>
            </Link>
          </li>

          {navItems.map(item => (
            <li key={item.index}>
              {item.separator ? (
                <span className="pagination-ellipsis">&hellip;</span>
              ) : (
                <Link
                  to={item.link}
                  className={`pagination-link ${
                    item.current ? "page-item active" : "page-item"
                  }`}
                  aria-label={`Goto page ${item.index}`}
                >
                  {item.index}
                </Link>
              )}
            </li>
          ))}
          {/* output next page navigation */}
          <li className={forwardClass}>
            <Link
              to={`${props.pagePath}/${nextUrl}`}
              className="page-link pagination-arrow"
              rel="nofollow"
            >
              <Arrow />
            </Link>
          </li>
        </ul>
      </StyledPagination>
    );
  } else
    return (
      <StyledPagination>
        <p />
      </StyledPagination>
    );
};

export default Pagination;
